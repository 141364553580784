.co-wrapper
{
    display: flex;
    padding: 0 10rem;
    justify-content: space-between;
    padding-bottom: 5rem;
}

.co-left
{
    
    flex-direction: column;
    display: flex;
    justify-content:center ;
    gap: 1rem;
    padding: 2rem;
}





.co-right
{
    display: flex;
    flex-direction: column;
   
    width: 25rem;
    background-color: rgb(228, 254, 236);
    gap: .5rem;
    padding: 1.5rem;
    border-radius: 8px ;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.co-right form
{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.co-right form input
{
    height: 28px;
    border-radius: 3px;
    border: 1px solid black;
}

.co-right form button
{
    width: 60px;
    height: 30px;
    background-color: green;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.form-button
{
    display: flex;
    justify-content: center;
    
}


.contact-main
{
   display: flex;
   flex-direction: column;
   margin: 4rem;
   align-items: center;
   padding-top: 4rem;
   
   gap: 1rem;
}


.co-left ul li
{
   list-style: none;
   margin-bottom: 10px;
}

.co-left .social-link
{
    display: flex;
    gap: 1rem;
}

.co-left .social-link a
{
    height: 35px;
    width: 35px;
    background-color: green;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 50%;
    color: white;
}

.co-right h3
{
    text-align: center;
    padding: 1rem 0;
}


@media (max-width: 991px)
{
    .co-wrapper
    {
        
    flex-direction: column;
    padding: 0 0;

    }

    .contact-main h2
    {
        text-align: center;
    }

    .co-left
    {
      align-items: center;
    }

    .co-right
    {
        width: auto;
        scale: 90%;
    }
}

