.certificate {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
}

.certificate-img {
  width: 40%;
}

@media (max-width: 991px) {
  .certificate-img {
    width: 100%;
  }
}
