.about-main {
  display: flex;
  flex-direction: row;
}
.left-pane,
.right-pane {
  width: 50%;
}

.about-image {
  width: 100%;
}
.right-pane {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  padding-left: 1rem;
}
.right-pane h2 {
  color: green;
}

@media (max-width: 480px) {
  .about-main {
    display: flex;
    flex-direction: column-reverse;
  }
  .right-pane {
    width: 100%;
    padding: 20px;
    margin-top: 5rem;
    gap: 2rem;
  }
  .about-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .left-pane {
    width: 100%;
    margin-top: 1rem;
    padding-left: 3rem;
  }
}
